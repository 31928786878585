.hide {
    display: none!important;
}
.F {
    color: 'FF0080'!important;
}
.M {
    color: black;
}

.margenesGrid {
    margin-top: 5px!important;
    margin-bottom: 5px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-right: 10px!important;
}

.subtitulo {
    font-weight: 900;
    font-size: 1.2em;
}