.hide {
    display: none !important;
}
.F {
    color: 'FF0080' !important;
}
.M {
    color: black;
}

.margenesGrid {
    margin-top: 7px!important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
}

.cellTotal {
    background-color: #01579b!important; 
    color : white!important;
    padding: 0px!important;
    text-align: center!important;
}